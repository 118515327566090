import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Version: process.env.VUE_APP_VERSION,
  },
})

export default {
  getClients() {
    return apiClient.get(`/api/v1/clients?pageSize=1000`)
  },
  updateClient(client) {
    return apiClient.patch(`/api/v1/clients/${client.id}`, client)
  },
  createClient(client) {
    return apiClient.post(`/api/v1/clients`, client)
  },
  deleteClient(clientId) {
    return apiClient.delete(`/api/v1/clients/${clientId}`)
  },
  checkClientRegistrationNumber(registrationNumber) {
    return apiClient.get(
      `/api/v1/clients/checkClient?registrationNumber=${registrationNumber}`
    )
  },
}
